/* ======================================================================== */
/* 12. PJAX Finish Loading */
/* ======================================================================== */
function PJAXFinishLoading(data) {
	return new Promise((resolve) => {

		// Transition ended event
		window.dispatchEvent(new CustomEvent('arts/barba/transition/end'));

		// Hide spinner
		if (typeof window.$spinner !== 'undefined' && window.$spinner.length) {
			gsap.to(window.$spinner, 0.6, {
				autoAlpha: 0
			});
		}

		// Re-init page header
		if (typeof window.theme.header !== 'undefined') {
			window.theme.header.run();
		}

		// re-enable ScrollMagic scenes
		window.SMController.enabled(true);
		window.SMController.update(true);

		setTimeout(() => {

			// remove cloned image if it exists
			$('.clone').remove();

			// unlock scroll
			Scroll.lock(false);

			window.$barbaWrapper.removeClass('cursor-progress');
			$('.menu').removeClass('menu_disabled');

			// refresh animation triggers
			// for Waypoints library
			if (typeof Waypoint !== 'undefined') {
				Waypoint.refreshAll();
			}

		}, 100);

		// scroll to anchor from URL hash
		Scroll.scrollToAnchorFromHash();

		resolve(true);

	});

}
