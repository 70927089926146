/* ======================================================================== */
/* 71. MobileBarHeight */
/* ======================================================================== */
class MobileBarHeight {
	constructor() {
		this.vh = 0;
		this._createStyleElement();
		this._setVh();
		if (window.theme.mobileBarFix.update) {
			this._bindEvents();
		}
	}

	_setVh() {
		this.vh = window.innerHeight * 0.01;
		$('#arts-fix-bar').html(`:root { --fix-bar-vh: ${this.vh}px; }`);
	}

	_bindEvents() {
		const event = window.Modernizr.touchevents ? 'orientationchange' : 'resize';

		window.$window.on(event, debounce(() => {
			this._setVh();
		}, 250))
			.on('arts/barba/transition/clone/before', this._setVh());
	}

	_createStyleElement() {
		if (!$('#arts-fix-bar').length) {
			$('head').append('<style id="arts-fix-bar"></style>');
		}
	}
}
