/* ======================================================================== */
/* 28. PJAX */
/* ======================================================================== */
class PJAX extends BaseComponent {
	constructor({
		target,
		scope
	}) {
		super({
			target,
			scope
		});
	}

	run() {

		barba.init({
			timeout: 10000,
			cacheIgnore: window.Modernizr.touchevents ? true : false, // don't grow cache on mobiles
			// don't trigger barba for links outside wrapper
			prevent: ({
				el
			}) => {

				let
					$el = $(el),
					url = $el.attr('href'),
					customRules = sanitizeSelector(window.theme.ajax.preventRules),
					exludeRules = [
						'.no-ajax',
						'.no-ajax a',
						'[data-elementor-open-lightbox]', // Elementor lightbox gallery
						'[data-elementor-lightbox-slideshow]', // Elementor Pro Gallery
						'.lang-switcher a', // Polylang & WPML language switcher
						'.js-gallery a', // any links in theme galleries
						'.js-album', // albums links
					];

				if (
					url === '#' || // dummy link
					url.indexOf('wp-admin') > -1 ||	// WordPress admin link
					url.indexOf('wp-login') > -1 || // WordPress login link
					url.indexOf('/feed/') > -1 // WordPress feed
				) {
					return true;
				}

				// page anchor
				if ($el.is('[href*="#"]') && window.location.href === url.substring(0, url.indexOf('#'))) {
					return true;
				}

				// page anchor
				if ($el.is('[href^="#"]')) {
					return true;
				}

				// elementor preview
				if (typeof elementor === 'object') {
					return true;
				}

				// clicked on elementor outside barba wrapper
				if ($el.closest(window.$barbaWrapper).length < 1) {
					return true;
				}

				// custom rules from WordPress Customizer
				if (customRules) {
					exludeRules = [...exludeRules, ...customRules.split(',')];
					exludeRules = [...new Set(exludeRules)];
				}

				// check against array of rules to prevent
				return $el.is(exludeRules.join(','));

			},
			// custom transitions
			transitions: [
				PJAXTransitionGeneral,
				PJAXTransitionFlyingImage,
				PJAXTransitionOverlayMenu,
				PJAXTransitionFullscreenSlider,
				PJAXTransitionListHover,
				PJAXTransitionBlog
			],

		});

		window.$document.on('click', '[data-barba="wrapper"] [data-pjax-link]', () => {
			window.dispatchEvent(new CustomEvent('arts/barba/transition/click'));
		});
	}

	static getNextPageElement({
		url,
		element
	}) {
		return new Promise((resolve) => {
			barba
				.request(url)
				.then((res) => {
					resolve($($.parseHTML(res)).find(element));
				});
		});
	}
}
