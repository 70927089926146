/* ======================================================================== */
/* 81. elementor */
/* ======================================================================== */
/**
 * Elementor Preview
 */
window.$window.on('elementor/frontend/init', function () {

  if (typeof elementor === 'undefined') {
    return;
  }

  /**
   * Static Widgets
   */
  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-button.default', ($scope) => {
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-circle-button.default', ($scope) => {
    new CircleButton({
      target: $scope.find('.js-circle-button:not(.js-circle-button_curved):not(.section-masthead .js-circle-button)'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-content-block.default', ($scope) => {
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-counters.default', ($scope) => {
    new AsideCounters({
      target: $scope.find('.aside-counters'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-feature.default', ($scope) => {
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-google-map.default', ($scope) => {
    new GMap({
      target: $scope.find('.js-gmap'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-lightbox-video.default', ($scope) => {
    new SectionImage({
      target: $scope.find('.section-image'),
      scope: $scope
    });

    new PSWPGallery({
      target: $scope.find('.js-gallery:not(.js-gallery-united .js-gallery), .js-gallery-united'), // exclude inner galleries
      scope: $scope,
      options: {
        history: false,
        showAnimationDuration: 300,
      }
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-logo-description.default', ($scope) => {
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-masonry-grid.default', ($scope) => {
    new SectionGrid({
      target: $scope.find('.section-grid'),
      scope: $scope
    });

    new PSWPGallery({
      target: $scope.find('.js-gallery:not(.js-gallery-united .js-gallery), .js-gallery-united'), // exclude inner galleries
      scope: $scope,
      options: {
        history: false,
        showAnimationDuration: 300,
      }
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-parallax-background.default', ($scope) => {
    new SectionImage({
      target: $scope.find('.section-image'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-project-properties.default', ($scope) => {
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-slider-images.default', ($scope) => {
    new SectionSliderImages({
      target: $scope.find('.section-slider-images'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-slider-testimonials.default', ($scope) => {
    new SectionTestimonials({
      target: $scope.find('.section-testimonials'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-team-member.default', ($scope) => {
    new SectionImage({
      target: $scope.find('.section-image'),
      scope: $scope
    });
  });

  /**
   * Dynamic Widgets Preview
   */
  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-albums-covers-list.default', ($scope) => {
    new PSWPAlbum({
      target: $scope.find('.js-album'),
      scope: $scope,
      options: {
        history: false,
        showAnimationDuration: 300,
      }
    });
    new SectionList({
      target: $scope.find('.section-list'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-albums-covers-slider.default', ($scope) => {
    new SectionProjectsSlider({
      target: $scope.find('.section-projects-slider'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-albums-mouse-hover-reveal.default', ($scope) => {
    new SectionList({
      target: $scope.find('.section-list'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-portfolio-fullscreen-slider.default', ($scope) => {
    new SectionProjectsSlider({
      target: $scope.find('.section-projects-slider'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-portfolio-halfscreen-slider.default', ($scope) => {
    new SectionProjectsSlider({
      target: $scope.find('.section-projects-slider'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-portfolio-irregular-grid.default', ($scope) => {
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-portfolio-masonry-grid.default', ($scope) => {
    new SectionGrid({
      target: $scope.find('.section-grid'),
      scope: $scope
    });
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-services-grid.default', ($scope) => {
    new SectionGrid({
      target: $scope.find('.section-grid'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-portfolio-mouse-hover-reveal.default', ($scope) => {
    new SectionList({
      target: $scope.find('.section-list'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-portfolio-reveal-background-slider.default', ($scope) => {
    new SectionProjectsSlider({
      target: $scope.find('.section-projects-slider'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-services-slider.default', ($scope) => {
    new SectionSliderImages({
      target: $scope.find('.section-slider-images'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-services-content-block.default', ($scope) => {
    new SectionImage({
      target: $scope.find('.section-image'),
      scope: $scope
    });
    new SectionContent({
      target: $scope.find('.section-content'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/rhye-widget-image-mouse-hover-reveal.default', ($scope) => {
    new SectionList({
      target: $scope.find('.section-list'),
      scope: $scope
    });
  });

  elementorFrontend.hooks.addAction('frontend/element_ready/global', function ($scope) {

    new LazyLoad({
      scope: $scope,
      setPaddingBottom: false,
      run: true
    });

    new ArtsParallax({
      target: $scope.find('[data-arts-parallax]'),
      factor: 0.3,
      ScrollMagicController: window.SMController,
      SmoothScrollBarController: window.SB
    });

    new ChangeTextHover({
      target: $scope.find('.js-change-text-hover:not(.js-change-text-hover .js-change-text-hover)'), // exclude nested elements
      scope: $scope,
      pageIndicator: $scope.find('.js-page-indicator'), // fixed page indicator
      triggers: $scope.find('.js-page-indicator-trigger'), // elements that triggers the change of page indicator
    });

    new CircleButton({
      target: $scope.find('.js-circle-button:not(.js-circle-button_curved):not(.section-masthead .js-circle-button)'),
      scope: $scope
    });

    SetText.splitText({
      target: $scope.find('.js-split-text')
    })
      .then(() => SetText.setLines({
        target: $scope.find('[data-arts-os-animation] .split-text[data-split-text-set="lines"]')
      }))
      .then(() => SetText.setWords({
        target: $scope.find('[data-arts-os-animation] .split-text[data-split-text-set="words"]')
      }))
      .then(() => SetText.setChars({
        target: $scope.find('[data-arts-os-animation] .split-text[data-split-text-set="chars"]')
      }));

  });

});
