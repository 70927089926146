/* ======================================================================== */
/* 13. PJAX Init New Page */
/* ======================================================================== */
function PJAXInitNewPage(data) {
	return new Promise((resolve) => {

		const
			$nextContainer = $(data.next.container),
			$elementorSections = $nextContainer.find('.elementor-section'),
			$cf7Forms = $nextContainer.find('.wpcf7-form'),
			$elementorPopups = $('.elementor-popup-modal');

		Promise
			.all([
				PJAXUpdateBody(data),
				PJAXUpdateNodes(data),
				PJAXUpdateHead(data),
				PJAXUpdateAdminBar(data),
				PJAXUpdateLanguageSwitcher(data),
				document.fonts.ready,
			])
			.then(() => {
				return new Promise((resolve) => {
					// load images
					new LazyLoad({
						scope: $nextContainer,
						setPaddingBottom: false,
						run: true
					});
					setTimeout(() => {
						resolve(true);
					}, 300);
				})
			})
			.then(() => SetText.splitText({
				target: $nextContainer.find('.js-split-text, .header__widget > *')
			}))
			.then(() => SetText.setLines({
				target: $nextContainer.find('[data-arts-os-animation] .split-text[data-split-text-set="lines"]')
			}))
			.then(() => SetText.setWords({
				target: $nextContainer.find('[data-arts-os-animation] .split-text[data-split-text-set="words"]')
			}))
			.then(() => SetText.setChars({
				target: $nextContainer.find('[data-arts-os-animation] .split-text[data-split-text-set="chars"]')
			}))
			.then(() => {
				// Elementor Pro sticky effects handling
				if ($elementorSections.length) {
					gsap.set($elementorSections, {
						clearProps: 'all',
					});
					$elementorSections.removeClass('elementor-sticky--active');
				}
				$nextContainer.find('.elementor-sticky__spacer').remove();

				// Elementor Animated Headline reset
				$nextContainer.find('.elementor-headline-animation-type-typing .elementor-headline-dynamic-wrapper').empty();

				// Elementor Pro Lottie animations reset
				$nextContainer.find('.e-lottie__animation').empty();

				// re-init Contact Form 7
				if ($cf7Forms.length && typeof wpcf7 !== 'undefined' && typeof wpcf7.initForm === 'function') {
					wpcf7.initForm($cf7Forms);
				}

				// re-init Contact Form 7 Conditional Fields Plugin
				if ($cf7Forms.length && typeof wpcf7cf !== 'undefined' && typeof wpcf7cf.initForm === 'function') {
					wpcf7cf.initForm($cf7Forms);
				}

				// remove any opened Elementor popups
				if ($elementorPopups.length) {
					$elementorPopups.remove();
				}

				// scroll at the page beginning
				Scroll.scrollToTop();
				// Scroll.scrollTo({
				// 	x: 0,
				// 	y: 0,
				// 	duration: 0
				// });

				// autoplay paused HTML5 videos
				$('video[playsinline][muted][autoplay]').each(function () {
					if (this.paused) {
						this.play();
					}
				});

				// clear & re-init ScrollMagic
				window.SMController.destroy();
				window.SMController = new ScrollMagic.Controller();

				// Transition init new page event (before components init)
				window.dispatchEvent(new CustomEvent('arts/barba/transition/init/before'));

				// re-init components
				initComponents({
					scope: $nextContainer,
					container: $nextContainer,
					scrollToHashElement: false // will scroll to the anchors later after once transition is fully finished
				});

				// don't start animations immediately
				window.SMController.enabled(false);

				if (window.theme.ajax.evalInlineContainerScripts) {

					// eval inline scripts in the main container
					$nextContainer.find('script').each(function () {
						try {
							window.eval(this.text);
						} catch (error) {
							console.warn(error);
						}
					});

				}

				// ensure that scroll is still locked
				// Scroll.lock(true);

				// Transition init new page event (after components init)
				window.dispatchEvent(new CustomEvent('arts/barba/transition/init/after'));

				// init Elementor frontend
				if (typeof window.elementorFrontend !== 'undefined') {
					elementorFrontend.init();
				}

				// update ad trackers
				PJAXUpdateTrackers();

				resolve(true);
			})
			.catch((e) => {
				barba.force(data.next.url.href);
				console.warn(e);
			});
	});
}
