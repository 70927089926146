/* ======================================================================== */
/* 14. PJAX Set Next Container */
/* ======================================================================== */
function PJAXSetNextContainer(data) {
  return new Promise((resolve) => {
    const
      $nextContainer = $(data.next.container),
      $nextMasthead = $nextContainer.find('.section-masthead'),
      $nextMastheadBg = $nextMasthead.find('.section-masthead__background'),
      tl = new gsap.timeline();

    if ($nextMasthead.length) {
      $nextMasthead.attr('data-arts-os-animation', 'animated');
    }

    if ($nextMastheadBg.length) {
      $nextMastheadBg.addClass('js-cancel-animation');
    }

    tl
      .set($nextContainer, {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 300,
        autoAlpha: 0,
      })
      .add(() => {
        resolve(true);
      });

  });
}
