/* ======================================================================== */
/* 59. SectionScroll */
/* ======================================================================== */
class SectionScroll extends ScrollAnimation {
	constructor({
		target,
		scope
	}) {
		super({
			target,
			scope
		});
  }
  set() {
    const self = this;

    this.$el.each(function () {
      const
        $el = $(this),
        duration = $el.innerHeight(),
        defaultTheme = $el.data('arts-default-theme'),
        defaultColor = $el.data('arts-default-color'),
        scrollTheme = $el.data('arts-scroll-theme'),
        scrollColor = $el.data('arts-scroll-color'),
        offset = parseFloat($el.data('arts-scroll-offset')),
        triggerHook = parseFloat($el.data('arts-scroll-trigger-hook')),
        scene = self._createScene({
          element: $el,
          triggerHook,
          offset,
          duration
        });

      scene
        .on('enter', () => {
          $el.removeClass([defaultColor, defaultTheme].join(' ')).addClass([scrollColor, scrollTheme].join(' '));
        })
        .on('leave', () => {
          $el.removeClass([scrollColor, scrollTheme].join(' ')).addClass([defaultColor, defaultTheme].join(' '));
        });
    });

  }
  _createScene({
    element,
    duration = 0,
    offset = 0,
    triggerHook = 0
  }) {
    return new $.ScrollMagic.Scene({
        triggerElement: element,
        triggerHook,
        reverse: true,
        duration,
        offset
      })
      .addTo(window.SMController);
  }
}
