/* ======================================================================== */
/* 52. SectionContent */
/* ======================================================================== */
class SectionContent extends ScrollAnimation {
	constructor({
		target,
		scope
	}) {
		super({
			target,
			scope
		});
	}
	set() {
		this.$headline = this.$el.find('.section__headline');
		this.$heading = this.$el.find('.section-content__heading');
		this.$text = this.$el.find('.section-content__text');
		this.$trigger = this.$el.find('.section-content__inner');
		this.$button = this.$el.find('.section-content__button');
		this.$imageInner = this.$el.find('.section-content__image');
		this.$bgInner = this.$el.find('.section-content__bg');
		this.$socialItems = this.$el.find('.social__item').not('.section-blog__sidebar .social__item');
		this.$wrapperSD = this.$el.find('.section-masthead__wrapper-scroll-down');

		if (!this._hasAnimationScene(this.$el)) {

			// hide hover lines
			gsap.effects.hideLines(this.$el.find('.js-change-text-hover__hover'), {
				ease: 'power3.out',
				duration: 0,
				stagger: 0,
			});
			return;
		}

		if (this.$headline.length) {
			gsap.set(this.$headline, {
				scaleX: 0
			});
		}

		if (this.$button.length) {
			gsap.set(this.$button, {
				y: 30,
				autoAlpha: 0
			});
		}

		if (this.$bgInner.length) {
			gsap.set(this.$bgInner, {
				scale: isBrowserFirefox() ? 1.0 : 1.05,
				transformOrigin: 'center center',
				autoAlpha: 0
			});
		}

		if (this.$imageInner.length) {
			gsap.set(this.$imageInner, {
				scaleY: 1.5,
				y: '33%',
				transformOrigin: 'top center',
				autoAlpha: 0,
			});
		}

		if (this.$socialItems.length) {
			gsap.set(this.$socialItems, {
				y: 30,
				autoAlpha: 0
			});
		}

		if (this.$wrapperSD.length) {
			gsap.set(this.$wrapperSD, {
				y: 30,
				autoAlpha: 0.01
			});
		}
	}
	run() {
		const
			tl = new gsap.timeline(),
			timingLines = (this.$heading.length && this.$text.length ) ? '<0.2' : 'start';

		if (!this._hasAnimationScene(this.$el)) {
			return;
		}

		if (this.$bgInner.length) {
			tl.to(this.$bgInner, {
				duration: 2.4,
				autoAlpha: 1,
				scale: 1
			}, 'start');
		}

		tl
			.animateWords(this.$el, {
				ease: 'power3.out',
				duration: 1.2,
				stagger: 0.04,
			}, 'start')
			.animateLines(this.$el, {
				excludeEl: '.js-change-text-hover__hover .split-text__line',
				ease: 'power3.out',
				duration: 1.2,
				stagger: 0.06,
			}, timingLines);

		if (this.$socialItems.length) {
			tl.to(this.$socialItems, {
				y: 0,
				autoAlpha: 1,
				stagger: 0.05,
				duration: 0.6
			}, '<0.2');
		}

		if (this.$headline.length) {
			tl.animateHeadline(this.$headline, 'start')
		}

		if (this.$button.length) {
			tl.to(this.$button, {
				duration: 0.6,
				y: 0,
				autoAlpha: 1
			}, '<0.6');
		}

		if (this.$wrapperSD.length) {
			tl.to(this.$wrapperSD, {
				duration: 0.6,
				y: 0,
				autoAlpha: 1
			}, '<0.2');
		}

		this._createScene({
			element: this.$el,
			timeline: tl,
			customTrigger: this.$trigger
		});

		if (this.$imageInner && this.$imageInner.length) {
			const tlImage = new gsap.timeline();

			tlImage.to(this.$imageInner, {
				duration: 0.9,
				autoAlpha: 1,
				y: '0%',
				force3D: true,
				scaleY: 1,
				ease: 'power3.out',
			});

			this._createScene({
				element: this.$imageInner,
				triggerHook: 1,
				reveal: false,
				timeline: tlImage,
			});
		}

	}
}
