/* ======================================================================== */
/* 61. SectionTestimonials */
/* ======================================================================== */
class SectionTestimonials extends ScrollAnimation {
  constructor({
    target,
    scope
  }) {
    super({
      target,
      scope
    });
  }

  set() {
    this.$activeSlide = this.$el.find('.js-slider-testimonials .swiper-slide').eq(0);
    this.$activeSign = this.$activeSlide.find('.figure-testimonial__sign');
    this.$activeDescription = this.$activeSlide.find('.slider-testimonials__text');

    if (this._hasAnimationScene(this.$el)) {

      if (this.$activeSign.length) {
        gsap.set(this.$activeSign, {
          y: 50,
          autoAlpha: 0
        });
      }

      if (this.$activeDescription.length) {
        gsap.effects.hideLines(this.$activeDescription, {
          y: '100%',
          duration: 0
        });
      }

    }
  }

  run() {
    const
      slider = new SliderTestimonials({
        target: this.$el.find('.js-slider-testimonials')
      }),
      tl = new gsap.timeline({
        paused: true,
        onComplete: () => {
          if (slider.slider.params.autoplay.enabled) {
            slider.slider.autoplay.start();
          }
        }
      });

    if (this._hasAnimationScene(this.$el)) {

      if (this.$activeDescription.length) {
        tl.animateLines(this.$activeDescription, {
          duration: 1.2,
          from: 'start',
          ease: 'power3.out',
          stagger: 0.08,
          delay: 0.2
        }, 'start');
      }

      if (this.$activeSign.length) {
        tl.to(this.$activeSign, {
          duration: 1.2,
          y: 0,
          autoAlpha: 1,
          ease: 'power3.out',
        }, 'start');
      }

      tl.play();

      this._createScene({
        element: this.$el,
        timeline: tl
      });

    } else {
      tl.to(null, {
        duration: 1
      }).play();

      this._createScene({
        element: this.$el,
        timeline: tl,
        reveal: false,
      });
    }
  }
}
