/* ======================================================================== */
/* 60. SectionSliderImages */
/* ======================================================================== */
class SectionSliderImages extends ScrollAnimation {
  constructor({
    target,
    scope
  }) {
    super({
      target,
      scope
    });
  }
  set() {
    this.$slides = this.$el.find('.swiper-slide');

    if (this._hasAnimationScene(this.$el)) {
      gsap.set(this.$slides, {
        x: '33%',
        autoAlpha: 0,
        transformOrigin: 'right center'
      });
    }
  }
  run() {
    const
      slider = new SliderImages({
        target: this.$el.find('.js-slider-images')
      }),
      tl = new gsap.timeline({
        paused: true,
        onStart: () => {
          if (slider.slider.params.autoplay.enabled) {
            slider.slider.autoplay.start();
          }
        }
      });

    if (this._hasAnimationScene(this.$el)) {
      tl
        .to(this.$slides, {
          delay: 0.2,
          duration: 1.2,
          autoAlpha: 1,
          x: '0%',
          stagger: 0.1,
          ease: 'power3.out',
        }).play();

        this._createScene({
          element: this.$el,
          timeline: tl,
        });
    } else {
      tl.to(null, {
        duration: 1
      }).play();

      this._createScene({
        element: this.$el,
        timeline: tl,
        reveal: false,
      });
    }
  }
}
