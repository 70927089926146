/* ======================================================================== */
/* 38. fontObserver */
/* ======================================================================== */
function fontObserver() {

	return new Promise(function (resolve, reject) {

		const observers = [];

		// bypass if there are no fonts came from backend
		// or if the WP Customizer preview is active
		if (!window.theme.fonts || (typeof wp !== 'undefined' && typeof wp.customize !== 'undefined')) {
			resolve(true);
			return;
		}

		$.each(window.theme.fonts, function () {
			const currentObserver = new FontFaceObserver(this);

			observers.push(currentObserver.load(null, 8000));
		});

		Promise
			.all(observers)
			.then(() => {
				resolve(true);
			})
			.catch(() => {
				const
					errorHeading = 'Fonts Loading Failed',
					errorMessage = 'Font Observer: There is a critical error occured while loading one or more theme fonts.',
					errorDescription = 'This message won\'t appear to the website visitors.';

				console.error(errorMessage);
				if ( typeof elementorCommon !== 'undefined' ) {
					elementorCommon.dialogsManager.createWidget('confirm', {
						id: 'elementor-fatal-error-dialog',
						headerMessage: errorHeading,
						message: `${errorMessage}<br><br>${errorDescription}`,
						strings: {
							confirm: 'How to Fix?',
							cancel: 'Close'
						},
						onConfirm: () => {
							return window.open('http://docs.artemsemkin.com/rhye/wp/tips-tricks/troubleshooting-fonts-loading-issues.html', '_blank');
						},
					}).show();
				}
				reject(true);
			});

	});

}
