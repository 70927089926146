/* ======================================================================== */
/* 10. PJAX Clone Image */
/* ======================================================================== */
function PJAXCloneImage(target, customCoordinates, copyTransform = false) {
  return new Promise((resolve) => {
    if (!target.length) {
      resolve(true);
      return;
    }

    const
      tl = new gsap.timeline(),
      $clone = target.clone(),
      {
        top,
        left,
        width,
        height
      } = target.get(0).getBoundingClientRect();

    // Scroll.lock(true);
    if (target.find('video').length) {
      $clone.find('video').get(0).currentTime = target.find('video').get(0).currentTime;
    }

    $clone.addClass('clone');
    $clone.appendTo(window.$barbaWrapper);

    if (copyTransform) {
      tl.set($clone, {
        transform: target.css('transform'),
        transformOrigin: 'center center',
      });
    }

    tl
      .set($clone, {
        clipPath: target.css('clip-path'),
        position: 'fixed',
        top: customCoordinates ? customCoordinates.top : top,
        left: customCoordinates ? customCoordinates.left : left,
        width: customCoordinates ? customCoordinates.width : width,
        height: customCoordinates ? customCoordinates.height : height,
        zIndex: 350
      })
      .set($clone, {
        delay: 0.2,
        display: 'block',
      })
      .set(target, {
        autoAlpha: 0
      })
      .add(() => {
        resolve(true);
      })

  });
}
