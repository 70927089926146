/* ======================================================================== */
/* 78. runOnHighPerformanceGPU */
/* ======================================================================== */
function runOnHighPerformanceGPU() {
	const $webGLCanvas = $('#js-webgl');

	if (!window.Modernizr.touchevents && $webGLCanvas.length) {
		$webGLCanvas[0].getContext('webgl', {
			powerPreference: 'high-performance'
		});
	}

}
