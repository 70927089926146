/* ======================================================================== */
/* 16. PJAX Transition Blog */
/* ======================================================================== */
const PJAXTransitionBlog = {
  name: 'blog',
  from: {
    namespace: ['archive']
  },
  to: {
    namespace: ['archive']
  },

  before: (data) => {
    return new Promise((resolve) => {
      const
        $trigger = $(data.trigger),
        $sectionBlog = $(data.current.container).find('.section-blog'),
        $listItems = $sectionBlog.not('.js-ajax-filter').find('.section-grid__item');

      $sectionBlog.addClass('pointer-events-none');

      if ($trigger.is('.js-grid-ajax__filter a')) {
        data.trigger.type = 'filter';
      } else if ($trigger.is('.pagination a')) {
        data.trigger.type = 'pagination';
      }

      // hide current grid items
      if ($listItems.length) {
        gsap.to($listItems, {
          duration: 0.6,
          y: '-5%',
          transformOrigin: 'top center',
          autoAlpha: 0,
        });
      }

      // scroll up
      if (window.pageYOffset > 1) {
        Scroll.scrollTo({
          x: 0,
          y: 0,
          duration: 600,
          cb: () => {
            PJAXStartLoading(data).then(() => resolve(true));
          }
        });
      } else {
        PJAXStartLoading(data).then(() => resolve(true));
      }
    });
  },

  beforeLeave: (data) => {
    return new Promise((resolve) => {
      const
        tl = new gsap.timeline(),
        $containerCurrent = $(data.current.container),
        $sectionMasthead = $containerCurrent.find('.section-masthead'),
        $sectionBlog = $containerCurrent.find('.section-blog'),
        $grid = $sectionBlog.find('.js-grid'),
        $gridItems = $grid.find('.js-grid__item'),
        $heading = $sectionMasthead.find('.section-masthead__heading'),
        $subheading = $sectionMasthead.find('.section-masthead__subheading'),
        $headline = $sectionMasthead.find('.section__headline'),
        hasMastheadAnimation = $sectionMasthead.get(0).hasAttribute('data-arts-os-animation');

        $grid.isotope('hideItemElements', $gridItems);

      // update masthead heading
      if (data.trigger.type !== 'pagination' && hasMastheadAnimation) {
        tl
          .add([
            gsap.effects.hideWords($subheading, {
              duration: 0.6
            }),
            gsap.effects.hideWords($heading, {
              duration: 0.6,
              autoAlpha: 1
            }),
            gsap.effects.hideHeadline($headline, {
              duration: 0.4,
            })
          ])
          .add(() => {
            resolve(true);
          }, '-=0.3');
      } else {
        setTimeout(() => {
          resolve(true);
        }, 150);
      }
    });
  },

  beforeEnter: (data) => {
    return new Promise((resolve) => {
      const
        $sectionMasthead = $(data.next.container).find('.section-masthead'),
        $sectionBlog = $(data.next.container).find('.section-blog'),
        $ajaxFilter = $sectionBlog.find('.js-grid-ajax__filter, .js-filter'),
        $filterUnderline = $ajaxFilter.find('.filter__underline');

      $sectionBlog.attr('data-arts-os-animation', 'animated');
      $ajaxFilter.find('.js-split-text').removeClass('js-split-text');

      // don't animate underline twice
      $filterUnderline.addClass('js-cancel-animation');

      // don't animate masthead twice
      if (data.trigger.type === 'pagination') {
        $sectionMasthead.removeAttr('data-arts-os-animation');
        $sectionMasthead.find('[data-split-text-set]').removeAttr('data-split-text-set');
      }

      resolve(true);
    });
  },

  enter: (data) => {
    return new Promise((resolve) => {
      PJAXInitNewPage(data).then(() => {
        const
          $sectionMasthead = $(data.next.container).find('.section-masthead');

        if (data.trigger.type === 'pagination') {
          $sectionMasthead.find('.split-text__line').addClass('overflow');
        }
        resolve(true);
      });
    });
  },

  after: (data) => {
    return new Promise((resolve) => {
      $('.section-blog').removeClass('pointer-events-none');
      PJAXFinishLoading(data).then(() => resolve(true));
    });
  }
}
