/* ======================================================================== */
/* 19. PJAX Transition General */
/* ======================================================================== */
const PJAXTransitionGeneral = {
	before: (data) => {
		return new Promise((resolve) => {
			PJAXStartLoading(data).then(() => resolve(true));
		});
	},

	beforeLeave: (data) => {
		return new Promise((resolve) => {
			const tl = new gsap.timeline();

			tl
				.setCurtain()
				.add(() => resolve(true));
		});
	},


	beforeEnter: (data) => {
		return new Promise((resolve) => {
			const
				tl = new gsap.timeline(),
				$currentContainer = $(data.current.container),
				$nextContainer = $(data.next.container),
				$curtain = $('#js-page-transition-curtain'),
				$nextMasthead = $nextContainer.find('.section-masthead'),
				background = $nextMasthead.attr('data-background-color');

			tl
				.setCurtain($curtain, {
					background
				})
				.moveCurtain($curtain, {
					y: '0%',
					duration: 1.2
				})
				.set($nextContainer, {
					clearProps: 'all',
					autoAlpha: 1,
					zIndex: 300,
				})
				.set($currentContainer, {
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					zIndex: '-1',
					autoAlpha: 0
				})
				.add(() => resolve(true))
				.timeScale(window.theme.animations.timeScale.ajaxCurtainTransition || 1);

		});
	},

	enter: (data) => {
		return new Promise((resolve) => {
			PJAXInitNewPage(data).then(() => resolve(true) );
		});
	},

	afterEnter: (data) => {
		return new Promise((resolve) => {
			const
				tl = new gsap.timeline(),
				$curtain = $('#js-page-transition-curtain');

			tl
				.setCurtain($curtain)
				.add(() => resolve(true));
		});
	},


	after: (data) => {
		return new Promise((resolve) => {
			PJAXFinishLoading(data).then(() => resolve(true));
		});
	}

}
