/* ======================================================================== */
/* 54. SectionImage */
/* ======================================================================== */
class SectionImage extends ScrollAnimation {
	constructor({
		target,
		scope
	}) {
		super({
			target,
			scope
		});
  }

  set() {
		this.$mask = this.$el.find('.mask-reveal');
		this.$caption = this.$el.find('.section-image__caption');

		if (!this._hasAnimationScene(this.$el)) {
			return;
		}

		if (this.$mask.length) {
			gsap.effects.setMask(this.$mask);
			gsap.set(this.$caption, {
				y: 30,
				autoAlpha: 0,
			});
		} else {
			gsap.effects.setJump(this.$el);
		}
  }

	run() {
		const tl = new gsap.timeline();

		if (!this._hasAnimationScene(this.$el)) {
			return;
		}

		if (this.$mask.length) {
			tl
				.animateMask(this.$mask)
				.to(this.$caption, {
					duration: 0.6,
					y: 0,
					autoAlpha: 1
				}, '-=0.6');
		} else {
			tl.animateJump(this.$el);
		}

    this._createScene({
      element: this.$el,
      timeline: tl,
      triggerHook: 1,
    });
	}
}
